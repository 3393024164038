import React, { useState, useEffect } from 'react'
import { Redirect, Route } from 'react-router-dom'
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonViewWillEnter,
  IonRefresher,
  IonRefresherContent,
  IonButtons,
  IonBackButton,
  IonLoading
} from '@ionic/react'
import {
  caretDownOutline,
} from 'ionicons/icons'
import './Category.css'
import AdCategoryGrid from '../../components/AdCategoryGrid'
import AdGrid from '../../components/AdGrid'
import * as API from '../../api'
import { useTranslation } from 'react-i18next'
import { RefresherEventDetail } from '@ionic/core'
import { RouteComponentProps, useParams } from 'react-router'
import { category } from '../../types/api'

interface ContentProps extends RouteComponentProps<{ id: string }> { }
declare global {
  interface Window {
    URLPattern: any
  }
}

const Category: React.FC<ContentProps> = ({ match }) => {
  const { t } = useTranslation()
  const [showLoading, setShowLoading] = useState(false)
  const [categoriesData, setCategoriesData] = useState<any>()
  const [adsData, setAdsData] = useState<any>()
  const getId = () => parseInt(window.location.href.split('/').at(-1) ?? '0')//parseInt(new window.URLPattern(window.location.href).pathname.split('/')[2])//parseInt(match.params.id)
  useIonViewWillEnter(async () => {
    setCategoriesData([])
    setAdsData([])
    const id = getId()
    const data = await API.getCategoryList({ parent_id: id })
    setCategoriesData(data.filter((category: category) => category.status))
    setAdsData(await API.getAds({ category_id: id }))
  })
  const doRefresh = async (event: CustomEvent<RefresherEventDetail>) => {
    const id = getId()
    const data = await API.getCategoryList({ parent_id: id })
    setCategoriesData(data.filter((category: category) => category.status))
    setAdsData(await API.getAds({ category_id: id }))
    event.detail.complete()
  }
  return (
    <IonPage>
      <IonHeader translucent>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/categories" text={t('back')}></IonBackButton>
          </IonButtons>
          <IonTitle>{categoriesData?.[0]?.parent_category_title}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent
            pullingIcon={caretDownOutline}
            refreshingSpinner="circular"
            refreshingText="">
          </IonRefresherContent>
        </IonRefresher>
        <AdCategoryGrid categories={categoriesData} />
        <AdGrid ads={adsData} />
      </IonContent>
      <IonLoading
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={t('loading')} />
    </IonPage>
  )
}

export default Category
//https://stackoverflow.com/questions/41280471/how-to-implement-routereusestrategy-shoulddetach-for-specific-routes-in-angular