import './AdGrid.css'
import {
  IonGrid,
  IonRow,
  IonCol,
} from '@ionic/react'
import AdCard from './AdCard'

interface AdProps {
  ads: {
    id: number,
    title: string,
    image: string,
    startDate: Date,
    price: number,
    isFavorited: boolean,
    categoryTitle: string,
  }[]
}

const AdGrid: React.FC<AdProps> = ({ ads }) => {
  return (
    <IonGrid>
      {ads?.map((ad: any, index: number) => {
        return (
          <IonRow key={index}>
            <IonCol>
              <AdCard
                id={ad.product_id}
                title={ad.title}
                image={ad.image}
                startDate={new Date(ad.start_date.split('-').reverse().join('-')/*ad.start_date.replace(/(\d{2})-(\d{2})-(\d{4})/, '$3-$2-$1')*/)}
                price={ad.price}
                isFavorited={ad.is_favourite}
                categoryTitle={ad.category_title}
                ad={ad} />
            </IonCol>
          </IonRow>
        )
      })}
    </IonGrid>
  )
}

export default AdGrid