import { IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, useIonViewWillEnter } from '@ionic/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router'
import * as API from '../../api'
import './Content.css'

interface ContentProps extends RouteComponentProps<{ type: string }> { }//terms, privacy_policy, about_us

const Content: React.FC<ContentProps> = ({ match }) => {
  const { t } = useTranslation()
  const [content, setContent] = useState<string>('')
  useIonViewWillEnter(async () => { setContent((await API.getCMSContent({ type: match.params.type }))) })
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/account" text={t('back')}></IonBackButton>
          </IonButtons>
          <IonTitle>
            {match.params.type === 'terms' ? <IonTitle>{t('termsAndConditions')}</IonTitle> :
              match.params.type === 'privacy_policy' ? <IonTitle>{t('privacyPolicy')}</IonTitle> :
                match.params.type === 'about_us' ? <IonTitle>{t('aboutUs')}</IonTitle> :
                  <></>}
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div dangerouslySetInnerHTML={{
          __html: content
        }} className="ion-padding-horizontal"></div>
      </IonContent>
    </IonPage >
  )
}

export default Content
