import './AdCard.css'
import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonBadge,
  IonText
} from '@ionic/react'
import FavoriteButton from './FavoriteButton'
import { useTranslation } from 'react-i18next'
import { adListing } from '../types/api'

interface AdProps {
  id: number,
  title: string,
  image: string,
  startDate: Date,
  price: number,
  isFavorited: boolean,
  categoryTitle: string,
  ad: adListing
}

const AdCard: React.FC<AdProps> = ({ id, title, image, startDate, price, isFavorited, categoryTitle, ad }) => {
  const { t } = useTranslation()
  return (
    <IonCard className="ad-card ion-no-margin" routerLink={`/ad/${id}`} button={true}>
      <IonCardHeader className="ion-no-padding">
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonBadge color="warning">{categoryTitle}</IonBadge>
            </IonCol>
            <IonCol className="ion-text-end">
              <IonCardSubtitle>{startDate.toDateString()}</IonCardSubtitle>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonCardHeader>
      <IonCardContent className="ion-no-padding">
        <IonGrid>
          <IonRow>
            <IonCol size="5">
              <img src={image} className="ad-card-image" alt={`${title} image`} />
            </IonCol>
            <IonCol size="7">
              <IonGrid className="ion-no-padding">
                <IonRow>
                  <IonCardTitle>{title}</IonCardTitle>
                </IonRow>
                <IonRow>
                  <IonCol size="8" className="ion-no-padding">
                    <IonText color="warning"><h1>{price}</h1>{t('kd')}</IonText>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCol>
          </IonRow>
        </IonGrid>
        <FavoriteButton id={id} isFavorited={isFavorited} Fixed={true} ad={ad}/>
      </IonCardContent>
    </IonCard>
  )
}

export default AdCard