import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './languages/en.json'
import ar from './languages/ar.json'

i18n
  .use(initReactI18next)
  .init({
    debug: true,
    lng: 'en', // if you're using a language detector, do not define the lng option
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: { en, ar }
  })

export default i18n