import React, { useState, useEffect, FormEventHandler, useRef, useContext } from 'react'
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonImg,
  IonLabel,
  IonInput,
  IonItem,
  IonCheckbox,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonButtons,
  IonBackButton,
  IonLoading,
  useIonAlert
} from '@ionic/react'
import './LogIn.css'
import { useTranslation } from 'react-i18next'
import * as API from '../../api'
import { useHistory } from 'react-router'
import { UserContext } from '../../contexts'

const LogIn: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const prefersDark = window.matchMedia('(prefers-color-scheme: dark)')
  const [isDark, setDark] = useState(prefersDark.matches)
  window
    .matchMedia('(prefers-color-scheme: dark)')
    .addEventListener('change', e => { setDark(e.matches) })
  const emailInput = useRef<any>()
  const passwordInput = useRef<any>()
  const [showLoading, setShowLoading] = useState(false)
  const [showMessage] = useIonAlert()
  const currentUserContext = useContext(UserContext)
  const submit: FormEventHandler = async e => {
    e.preventDefault()
    setShowLoading(true)
    const response = await currentUserContext.logIn({
      email: emailInput.current.value,
      password: passwordInput.current.value
    })
    if (!currentUserContext.isAuthenticated) showMessage({
      message: t('incorrectCredentials'),
      buttons: [t('okay')],
      onDidDismiss: e => { emailInput.current.value = passwordInput.current.value = '' },
    })
    else {
      history.goBack()
    }
    setShowLoading(false)
  }
  return (
    <IonPage>
      <IonHeader translucent>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/account" text={t('back')}></IonBackButton>
          </IonButtons>
          <IonTitle></IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonImg src={`/assets/logo.${isDark ? 'light' : 'dark'}.png`} className="ion-padding logo" />
        <form onSubmit={submit}>
          <IonItem className="ion-margin-end">
            <IonLabel position="floating">{t('email')}</IonLabel>
            <IonInput ref={emailInput} name="email" type="email" autocomplete="email" clearInput required />
          </IonItem>
          <IonItem className="ion-margin-end">
            <IonLabel position="floating">{t('password')}</IonLabel>
            <IonInput ref={passwordInput} name="password" type="password" autocomplete="current-password" clearInput required />
          </IonItem>
          <IonButton className="ion-margin" type="submit" expand="block">
            {t('logIn')}
          </IonButton>
        </form>
      </IonContent>
      <IonLoading
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={t('loading')} />
    </IonPage>
  )
}

export default LogIn
