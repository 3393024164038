import {
  IonContent,
  IonPage,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonAvatar,
  IonList,
  IonItem,
  IonIcon,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonLoading,
  IonImg,
  IonModal
} from '@ionic/react'
import {
  albumsOutline,
  settingsOutline,
  readerOutline,
  lockClosedOutline,
  informationCircleOutline,
  mailOutline,
  logOutOutline,
  logInOutline
} from 'ionicons/icons'
import './Account.css'
import { useTranslation } from 'react-i18next'
import { useContext, useReducer, useState } from 'react'
import { UserContext } from '../../contexts'

const Account: React.FC = () => {
  const { t } = useTranslation()
  const [showLoading, setShowLoading] = useState(false)
  const [showProfileModal, setShowProfileModal] = useState(false)
  const currentUserContext = useContext(UserContext)
  const logOut = async () => {
    setShowLoading(true)
    await currentUserContext.logOut()
    setShowLoading(false)
  }
  const profile = () => {
    setShowProfileModal(true)
  }
  return (
    <IonPage>
      <IonContent>
        {currentUserContext.isAuthenticated
          ?
          <>
            <IonModal isOpen={showProfileModal}>
              <p>Profile</p>
              <IonButton onClick={() => setShowProfileModal(false)}>{t('close')}</IonButton>
            </IonModal>
            <IonCard button onClick={profile}>
              <IonCardHeader>
                <IonGrid>
                  <IonRow>
                    <IonCol size="3">
                      <IonAvatar>
                        {currentUserContext.information.profile_pic === ''
                          ?
                          <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm0 22c-3.123 0-5.914-1.441-7.749-3.69.259-.588.783-.995 1.867-1.246 2.244-.518 4.459-.981 3.393-2.945-3.155-5.82-.899-9.119 2.489-9.119 3.322 0 5.634 3.177 2.489 9.119-1.035 1.952 1.1 2.416 3.393 2.945 1.082.25 1.61.655 1.871 1.241-1.836 2.253-4.628 3.695-7.753 3.695z" />
                          </svg>
                          :
                          <IonImg src={currentUserContext.information.profile_pic || ''} />
                        }
                      </IonAvatar>
                    </IonCol>
                    <IonCol size="9">
                      <IonCardSubtitle>{t('editProfile')}</IonCardSubtitle>
                      <IonCardTitle>{currentUserContext.information.name}</IonCardTitle>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonCardHeader>
              {/* <IonCardContent></IonCardContent> */}
            </IonCard>
          </>
          :
          <></>
        }
        <IonList className="ion-margin-top">
          {currentUserContext.isAuthenticated
            ?
            <IonItem button routerLink="/my">
              <IonIcon slot="start" icon={albumsOutline}></IonIcon>
              <IonLabel>
                {t('myAds')}
              </IonLabel>
            </IonItem>
            :
            <></>
          }
          <IonItem button routerLink="/settings">
            <IonIcon slot="start" icon={settingsOutline}></IonIcon>
            <IonLabel>
              {t('settings')}
            </IonLabel>
          </IonItem>
          <IonItem button routerLink="/content/terms">
            <IonIcon slot="start" icon={readerOutline}></IonIcon>
            <IonLabel>
              {t('termsAndConditions')}
            </IonLabel>
          </IonItem>
          <IonItem button routerLink="/content/privacy_policy">
            <IonIcon slot="start" icon={lockClosedOutline}></IonIcon>
            <IonLabel>
              {t('privacyPolicy')}
            </IonLabel>
          </IonItem>
          <IonItem button routerLink="/content/about_us">
            <IonIcon slot="start" icon={informationCircleOutline}></IonIcon>
            <IonLabel>
              {t('aboutUs')}
            </IonLabel>
          </IonItem>
          <IonItem button routerLink="/contact">
            <IonIcon slot="start" icon={mailOutline}></IonIcon>
            <IonLabel>
              {t('contactUs')}
            </IonLabel>
          </IonItem>
          {currentUserContext.isAuthenticated
            ?
            <IonItem button color="danger" onClick={logOut}>
              <IonIcon slot="start" icon={logOutOutline}></IonIcon>
              <IonLabel>
                {t('logOut')}
              </IonLabel>
            </IonItem>
            :
            <IonItem button routerLink="/login">
              <IonIcon slot="start" icon={logInOutline}></IonIcon>
              <IonLabel>
                {t('logIn')}
              </IonLabel>
            </IonItem>
          }
        </IonList>
      </IonContent>
      <IonLoading
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={t('loading')}
      />
    </IonPage>
  )
}

export default Account
