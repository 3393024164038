import { Redirect, Route } from 'react-router-dom'
import {
  setupIonicReact,
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from '@ionic/react'
import { IonReactHashRouter } from '@ionic/react-router'
import {
  homeOutline,
  gridOutline,
  addCircleOutline,
  heartOutline,
  personOutline
} from 'ionicons/icons'
import { useTranslation } from 'react-i18next'
import Home from './pages/Home/Home'
import Categories from './pages/Categories/Categories'
import Category from './pages/Categories/Category'
import Add from './pages/Add/Add'
import Favorites from './pages/Favorites/Favorites'
import Account from './pages/Account/Account'
import LogIn from './pages/LogIn/LogIn'
import My from './pages/My/My'
import Settings from './pages/Settings/Settings'
import Password from './pages/Settings/Password'
import Content from './pages/Content/Content'
import Contact from './pages/Contact/Contact'
import Ad from './pages/Ad/Ad'
import { useContext } from 'react'
import { UserContext } from './contexts'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/display.css'

/* Theme variables */
import './theme/variables.css'
import './theme/custom.css'

setupIonicReact({
  mode: 'ios'
})

const App: React.FC = () => {
  const { t } = useTranslation()
  const currentUserContext = useContext(UserContext)
  return (
    <IonApp>
      <IonReactHashRouter>
        <IonTabs>
          <IonRouterOutlet>
            <Route exact path="/home" component={Home} />
            <Route path="/categories" component={Categories} />
            <Route exact path="/category/:id" component={Category} />
            <Route exact path="/add" render={() => currentUserContext.isAuthenticated ? <Add /> : <LogIn />} />
            <Route exact path="/favorites" render={() => currentUserContext.isAuthenticated ? <Favorites /> : <LogIn />} />
            <Route exact path="/account" component={Account} />
            <Route exact path="/login" render={() => currentUserContext.isAuthenticated ? <Redirect to="/account" /> : <LogIn />} />
            <Route exact path="/my" component={My} />
            <Route exact path="/settings" component={Settings} />
            <Route exact path="/settings/password" component={Password} />
            <Route exact path="/content/:type" component={Content} />
            <Route exact path="/contact" component={Contact} />
            <Route exact path="/ad/:id" component={Ad} />
            <Route exact path="/">
              <Redirect to="/home" />
            </Route>
          </IonRouterOutlet>
          <IonTabBar slot="bottom">
            <IonTabButton tab="home" href="/home">
              <IonIcon icon={homeOutline} />
              <IonLabel>{t('home')}</IonLabel>
            </IonTabButton>
            <IonTabButton tab="categories" href="/categories">
              <IonIcon icon={gridOutline} />
              <IonLabel>{t('categories')}</IonLabel>
            </IonTabButton>
            <IonTabButton tab="add" href="/add">
              <IonIcon icon={addCircleOutline} />
              <IonLabel>{t('addAd')}</IonLabel>
            </IonTabButton>
            <IonTabButton tab="favorites" href="/favorites">
              <IonIcon icon={heartOutline} />
              <IonLabel>{t('favorites')}</IonLabel>
            </IonTabButton>
            <IonTabButton tab="account" href="/account">
              <IonIcon icon={personOutline} />
              <IonLabel>{t('account')}</IonLabel>
            </IonTabButton>
          </IonTabBar>
        </IonTabs>
      </IonReactHashRouter>
    </IonApp>
  )
}

export default App