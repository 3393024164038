import 'intl-tel-input/build/css/intlTelInput.css'
import React, { useContext, useState, useEffect, FormEventHandler, useRef, ChangeEventHandler } from 'react'
import {
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonLoading,
  IonPage,
  IonTitle,
  IonToggle,
  IonToolbar,
  useIonViewDidEnter,
  useIonViewWillEnter,
} from '@ionic/react'
import './Add.css'
import { useTranslation } from 'react-i18next'
import { UserContext } from '../../contexts'
import Form from 'react-bootstrap/Form'
import FormControl from 'react-bootstrap/FormControl'
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup'
import ToggleButton from 'react-bootstrap/ToggleButton'
import InputGroup from 'react-bootstrap/InputGroup'
import {
  logoWhatsapp,
  earthOutline,
  call,
  albumsOutline,
  pricetagOutline,
  textOutline,
  documentTextOutline,
  imagesOutline,
  addOutline
} from 'ionicons/icons'
import intlTelInput from 'intl-tel-input'
import 'intl-tel-input/build/js/utils'
import { addEditAd, getCountries, getCategoryList } from '../../api'
import { country, category } from '../../types/api'
import 'primeicons/primeicons.css'
import 'primereact/resources/themes/lara-light-indigo/theme.css'
import 'primereact/resources/primereact.css'
import { TreeSelect, TreeSelectEventNodeParams, TreeSelectSelectionKeys } from 'primereact/treeselect'

const Add: React.FC = () => {
  const { t } = useTranslation()
  const currentUserContext = useContext(UserContext)
  const [showLoading, setShowLoading] = useState<boolean>(false)
  const [countries, setCountries] = useState<country[]>()
  const [categories, setCategories] = useState<category[]>()
  const [adType, setAdType] = useState<number>(0) // Default to product
  const [phoneIntlInput, setPhoneIntlInput] = useState<intlTelInput.Plugin>()
  const [whatsappIntlInput, setWhatsappIntlInput] = useState<intlTelInput.Plugin>()
  const [categoryTree, setCategoryTree] = useState<any>()
  const [selectedCategoryKey, setSelectedCategoryKey] = useState<TreeSelectSelectionKeys>()
  const pageElement = useRef<HTMLElement>()
  const categoryInput = useRef<any>()
  const titleInput = useRef<any>()
  const countryInput = useRef<any>()
  const priceInput = useRef<any>()
  const phoneInput = useRef<any>()
  const whatsappInput = useRef<any>()
  const imagesInput = useRef<any>()
  const descriptionInput = useRef<any>()
  const autoRenewInput = useRef<any>()
  const handleAdTypeChange = async (val: number) => {
    setShowLoading(true)
    setAdType(val)
    const cats: category[] = await getCategoryList({ category_type: val })// 3: Both Products and Services
    setCategoryTree(mapCategorySelectTree(cats))
    setShowLoading(false)
  }
  const mapCategorySelectTree = (categories: category[]) => categories
    .map(category => ({
      key: category.category_id,
      label: category.category_title,
      data: category.category_title,
      selectable: false,
      leaf: false
    }))
  useIonViewWillEnter(async () => {
    if (countries?.length && categories?.length) { return }
    setShowLoading(true)
    setCountries(await getCountries())
    const cats: category[] = await getCategoryList({ category_type: adType })// 3: Both Products and Services
    setCategories(cats)
    setShowLoading(false)
    setCategoryTree(mapCategorySelectTree(cats))
  })
  const categorySelectChange: ChangeEventHandler = async e => {
    const category_id = categoryInput.current.value
    const categories = await getCategoryList({
      category_type: adType,
      parent_id: category_id,
    })
    if (categories?.length) setCategories(categories)
  }
  useIonViewDidEnter(() => {
    console.log(pageElement)
    const intlTelInputOptions = {
      allowDropdown: false,
      initialCountry: 'kw',
      onlyCountries: ['kw'],
      preferredCountries: ['kw'],
      customContainer: 'form-control p-0'//'bg-transparent border border-warning form-control',
    }
    if (!phoneIntlInput) setPhoneIntlInput(intlTelInput(phoneInput.current, intlTelInputOptions))
    if (!whatsappIntlInput) setWhatsappIntlInput(intlTelInput(whatsappInput.current, intlTelInputOptions))
  }, [phoneIntlInput, whatsappIntlInput])
  const submit: FormEventHandler = async e => {
    e.preventDefault()
    e.stopPropagation()
    setShowLoading(true)
    const category_id = selectedCategoryKey//categoryInput.current.value // TODO: Drop down
    const title = titleInput.current.value;
    const country_id = countryInput.current.value // TODO: Drop dowm
    const price = priceInput.current.value
    const phone_no = phoneIntlInput?.getNumber() ?? '+96500000000'
    const whatsapp_no = whatsappIntlInput?.getNumber() ?? '+96500000000'
    const images = imagesInput.current.files
    const description = descriptionInput.current.value
    const auto_renew = autoRenewInput.current.checked // TODO: Place HTML Element
    setShowLoading(false)
  }
  const categoryLoader = async (e: TreeSelectEventNodeParams) => {
    e.node.icon = 'pi pi-spin pi-spinner'
    setShowLoading(true)
    const categoryList = await getCategoryList({
      category_type: adType,
      parent_id: parseInt(e.node.key?.toString() ?? '0'),
    })
    if (categoryList.length) {
      e.node.children = mapCategorySelectTree(categoryList)
      e.node.icon = ''
    }
    else {
      e.node.leaf = true
      e.node.selectable = true
      e.node.icon = 'pi pi-circle'
    }
    setShowLoading(false)
  }
  return (
    <IonPage ref={pageElement}>
      <IonHeader translucent>
        <IonToolbar>
          <IonTitle>{t('addAd')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <Form onSubmit={submit}>
          <IonList className="" lines="none">
            <IonListHeader>
              <IonLabel>{t('adType')}</IonLabel>
            </IonListHeader>
            <IonItem>
              <ToggleButtonGroup type="radio" value={adType} onChange={handleAdTypeChange} name="type" className="w-100 mx-1">
                <ToggleButton id="product" value={0} variant="outline-warning">
                  {t('product')}
                </ToggleButton>
                <ToggleButton id="service" value={1} variant="outline-warning">
                  {t('service')}
                </ToggleButton>
              </ToggleButtonGroup>
            </IonItem>
            <IonListHeader>
              <IonLabel>{t('adDetails')}</IonLabel>
            </IonListHeader>
            <IonItem className="mb-3">
              <InputGroup>
                <InputGroup.Text className="bg-warning bg-gradient border border-warning">
                  <IonIcon icon={albumsOutline} />
                </InputGroup.Text>
                <TreeSelect
                  id="category"
                  value={selectedCategoryKey}
                  appendTo={pageElement.current}
                  options={categoryTree}
                  onChange={e => { setSelectedCategoryKey(e.value) }}
                  placeholder={t('select')}
                  onNodeExpand={categoryLoader}
                  className="form-control">
                </TreeSelect>
              </InputGroup>
            </IonItem>
            <IonItem className="mb-3">
              <InputGroup>
                <InputGroup.Text className="bg-warning bg-gradient border border-warning">
                  <IonIcon icon={earthOutline} />
                </InputGroup.Text>
                <Form.Select
                  id="country"
                  ref={countryInput}
                  name="country"
                  aria-describedby={t('country')}
                  aria-label={t('country')}
                  defaultValue={-1}
                  required>
                  <option value={-1} disabled>{t('select')}</option>
                  {countries?.map((country: country, index: number) => {
                    return (
                      <option key={index} value={country.country_id}>{country.countryname}</option>
                    )
                  })}
                </Form.Select>
              </InputGroup>
            </IonItem>
            <IonItem className="mb-3">
              <InputGroup>
                <InputGroup.Text className="bg-warning bg-gradient border border-warning">
                  <IonIcon icon={textOutline} />
                </InputGroup.Text>
                <FormControl
                  id="title"
                  as="input"
                  ref={titleInput}
                  type="text"
                  placeholder={t('title')}
                  aria-label={t('title')}
                  name="title"
                  aria-describedby="title"
                  required />
              </InputGroup>
            </IonItem>
            <IonItem className="mb-3">
              <InputGroup>
                <InputGroup.Text className="bg-warning bg-gradient border border-warning">
                  <IonIcon icon={pricetagOutline} />
                </InputGroup.Text>
                <FormControl
                  id="price"
                  as="input"
                  ref={priceInput}
                  type="text"
                  placeholder={t('price')}
                  aria-label={t('price')}
                  name="price"
                  aria-describedby="price"
                  required />
              </InputGroup>
            </IonItem>
            <IonItem className="mb-3">
              <InputGroup>
                <InputGroup.Text className="bg-warning bg-gradient border border-warning">
                  <IonIcon icon={call} />
                </InputGroup.Text>
                <FormControl
                  id="phone"
                  as="input"
                  ref={phoneInput}
                  type="tel"
                  placeholder={t('phone')}
                  aria-label={t('phone')}
                  name="phone"
                  aria-describedby="phone"
                  pattern="\d{8}"
                  required />
              </InputGroup>
            </IonItem>
            <IonItem className="mb-3">
              <InputGroup>
                <InputGroup.Text className="bg-warning bg-gradient border border-warning">
                  <IonIcon icon={logoWhatsapp} />
                </InputGroup.Text>
                <FormControl
                  id="whatsapp"
                  as="input"
                  ref={whatsappInput}
                  type="tel"
                  placeholder={t('whatsapp')}
                  aria-label={t('whatsapp')}
                  name="whatsapp"
                  aria-describedby="whatsapp"
                  pattern="\d{8}"
                  required />
              </InputGroup>
            </IonItem>
            <IonItem className="mb-3">
              <InputGroup>
                <InputGroup.Text className="bg-warning bg-gradient border border-warning">
                  <IonIcon icon={imagesOutline} />
                </InputGroup.Text>
                <FormControl
                  id="images"
                  as="input"
                  ref={imagesInput}
                  type="file"
                  placeholder={t('images')}
                  aria-label={t('images')}
                  name="images"
                  aria-describedby="images"
                  multiple={true}
                  accept="image/*"
                  required />
              </InputGroup>
            </IonItem>
            <IonItem className="mb-3">
              <InputGroup>
                <InputGroup.Text className="bg-warning bg-gradient border border-warning">
                  <IonIcon icon={documentTextOutline} />
                </InputGroup.Text>
                <FormControl
                  id="description"
                  as="textarea"
                  ref={descriptionInput}
                  placeholder={t('description')}
                  aria-label={t('description')}
                  name="description"
                  aria-describedby="description"
                  required />
              </InputGroup>
            </IonItem>
            <IonItem className="mb-3">
              <IonLabel>
                {t('autoRenew')}
              </IonLabel>
              <IonToggle ref={autoRenewInput} color="warning" checked />
            </IonItem>
            <IonItem className="mb-3">
              <InputGroup>
                <InputGroup.Text className="bg-warning bg-gradient border border-warning">
                  <IonIcon icon={addOutline} />
                </InputGroup.Text>
                <FormControl
                  as="input"
                  type="submit"
                  value={t<string>('addAd')}
                  className="bg-warning bg-gradient border border-warning py-3"
                  required />
                <InputGroup.Text className="bg-warning bg-gradient border border-warning">
                  <IonIcon icon={addOutline} />
                </InputGroup.Text>
              </InputGroup>
            </IonItem>
          </IonList>
        </Form>
      </IonContent>
      <IonLoading
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={t('loading')} />
    </IonPage>
  )
}

export default Add
