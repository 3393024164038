import './AdCategorySquare.css'
import {
  IonCard,
  IonImg,
} from '@ionic/react'

interface AdCategoryProps {
  id: number,
  title: string,
  image: string,
  type: number, //0 - Product, 1 - Service
  count: number,
}

const AdCategorySquare: React.FC<AdCategoryProps> = ({ id, title, image, type, count }) => {
  image = image.length ? image : '/assets/category.default.png'
  return (
    <IonCard className={`ad-category-square${count ? '' : ' disabled'}`} routerLink={`/category/${id}`} button>
      <IonImg src={image} ></IonImg>
      <h6 className="ion-text-center ad-category-square-title">{title}</h6>
    </IonCard >
  )
}

export default AdCategorySquare