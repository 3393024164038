import './AdCategoryGrid.css'
import {
  IonGrid,
  IonRow,
  IonCol,
} from '@ionic/react'
import AdCategorySquare from "./AdCategorySquare"

interface AdCategoryProps {
  categories: {
    category_id: number,
    category_title: string,
    image: string,
    category_type: number, //0 - Product, 1 - Service
    total_count: number,
  }[]
}

const AdCategoryGrid: React.FC<AdCategoryProps> = ({ categories }) => {
  return (
    <IonGrid>
      <IonRow>
        {categories?.map((category: any, index: number) => {
          return (
            <IonCol key={index} sizeXs="6" sizeSm="4" sizeMd="3" sizeLg="2" sizeXl="1">
              <AdCategorySquare
                id={category.category_id}
                title={category.category_title}
                image={category.image}
                type={category.category_type}
                count={category.total_count} />
            </IonCol>
          )
        })}
      </IonRow>
    </IonGrid>
  )
}

export default AdCategoryGrid