import React, { useContext, useState, useEffect, FormEventHandler, useRef } from 'react'
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonList,
  IonItem,
  IonLabel,
  IonLoading,
  IonListHeader,
  IonInput,
  IonButton,
  IonTextarea,
} from '@ionic/react'
import './Contact.css'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom';
import { contactUs } from '../../api'
import { UserContext } from '../../contexts'

const Contact: React.FC = () => {
  const { t } = useTranslation()
  const currentUserContext = useContext(UserContext)
  const history = useHistory()
  const [showLoading, setShowLoading] = useState(false)
  const nameInput = useRef<any>()
  const emailInput = useRef<any>()
  const phoneInput = useRef<any>()
  const subjectInput = useRef<any>()
  const messageTextArea = useRef<any>()
  const submit: FormEventHandler = async e => {
    e.preventDefault()
    setShowLoading(true)
    const responseJSON = await contactUs({
      name: nameInput.current.value,
      email: emailInput.current.value,
      phone: phoneInput.current.value,
      title: subjectInput.current.value,
      message: messageTextArea.current.value,
    })
    history.goBack()
    subjectInput.current.value = messageTextArea.current.value = ''
    setShowLoading(false)
  }
  return (
    <IonPage>
      <IonHeader translucent>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/account" text={t('back')}></IonBackButton>
          </IonButtons>
          <IonTitle>{t('contact')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <form onSubmit={submit}>
          <IonList className="">
            <IonListHeader>
              <IonLabel>{t('adDetails')}</IonLabel>
            </IonListHeader>
            <IonItem>
              <IonLabel position="stacked">{t('name')}</IonLabel>
              <IonInput required clearInput ref={nameInput} name="name" type="text" value={currentUserContext.information.name} disabled={currentUserContext.isAuthenticated} autofocus={!currentUserContext.isAuthenticated} />
            </IonItem>
            <IonItem>
              <IonLabel position="stacked">{t('email')}</IonLabel>
              <IonInput required clearInput ref={emailInput} name="email" type="email" value={currentUserContext.information.email} disabled={currentUserContext.isAuthenticated} />
            </IonItem>
            <IonItem>
              <IonLabel position="stacked">{t('phone')}</IonLabel>
              <IonInput required clearInput ref={phoneInput} name="phone" type="text" value={currentUserContext.information.phone_no} disabled={currentUserContext.isAuthenticated} />
            </IonItem>
            <IonItem>
              <IonLabel position="stacked">{t('subject')}</IonLabel>
              <IonInput required clearInput ref={subjectInput} name="subject" type="text" autofocus={currentUserContext.isAuthenticated} />
            </IonItem>
            <IonItem>
              <IonLabel position="stacked">{t('message')}</IonLabel>
              <IonTextarea required ref={messageTextArea} name="message" />
            </IonItem>
            <IonButton className="ion-margin-top ion-margin-horizontal" type="submit" expand="block">
              {t('submit')}
            </IonButton>
          </IonList>
        </form>
      </IonContent>
      <IonLoading
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={t('loading')} />
    </IonPage>
  )
}

export default Contact;
