import React, { useContext, useState, useEffect } from 'react'
import { Redirect, Route } from 'react-router-dom'
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonViewWillEnter,
  IonRefresher,
  IonRefresherContent,
  RefresherEventDetail,
  IonGrid,
  IonRow,
  IonCol,
  IonLoading,
  IonButtons,
  IonBackButton
} from '@ionic/react';
import { useTranslation } from 'react-i18next'
import './My.css';
import AdCard from '../../components/AdCard'
import * as API from '../../api'
import { UserContext } from '../../contexts'
import {
  bagOutline,
  caretDownOutline,
  newspaperOutline
} from 'ionicons/icons'

const My: React.FC = () => {
  const { t } = useTranslation()
  const [showLoading, setShowLoading] = useState(false)
  const [myAdsList, setMyAdsList] = useState<any>()
  const currentUserContext = useContext(UserContext)
  useIonViewWillEnter(async () => {
    if (!currentUserContext.isAuthenticated) { return }
    setMyAdsList(await API.getMyAds({}))
  })
  const doRefresh = async (event: CustomEvent<RefresherEventDetail>) => {
    setMyAdsList(await API.getMyAds({}))
    event.detail.complete()
  }
  return (
    <IonPage>
      <IonHeader translucent>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/account" text={t('back')}></IonBackButton>
          </IonButtons>
          <IonTitle>{t('myAds')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent
            pullingIcon={caretDownOutline}
            refreshingSpinner="circular"
            refreshingText="">
          </IonRefresherContent>
        </IonRefresher>
        <IonGrid className="ion-padding">
          <IonRow>
            {myAdsList?.map((ad: any, index: number) => {
              return (
                <IonCol key={index} size-xs="12" sizeSm="12" sizeMd="6" sizeLg="4" sizeXl="3">
                  <AdCard
                    id={ad.product_id}
                    title={ad.title}
                    image={ad.image}
                    startDate={new Date(ad.start_date.split('-').reverse().join('-'))}
                    price={ad.price}
                    isFavorited={ad.is_favourite}
                    categoryTitle={ad.category_title}
                    ad={ad} />
                </IonCol>
              )
            })}
          </IonRow>
        </IonGrid>
      </IonContent>
      <IonLoading
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={t('loading')} />
    </IonPage>
  );
};

export default My;
