import 'swiper/swiper-bundle.min.css'
import './Home.css'
import React, { useState, useEffect } from 'react'
import {
  IonContent,
  IonImg,
  IonPage,
  useIonViewWillEnter,
  IonList,
  IonListHeader,
  IonItem,
  IonRefresher,
  IonRefresherContent,
  IonGrid,
  IonRow,
  IonCol,
  IonLoading
} from '@ionic/react'
import AdCard from '../../components/AdCard'
import AdCategoryGrid from '../../components/AdCategoryGrid'
import * as API from '../../api'
import { useTranslation } from 'react-i18next'
import { caretDownOutline } from 'ionicons/icons'
import { RefresherEventDetail } from '@ionic/core'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react'
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper'

SwiperCore.use([Autoplay, Navigation, Pagination])

const slideOpts = {
  loop: true,
}

const Home: React.FC = () => {
  const { t } = useTranslation()
  const [homeData, setHomeData] = useState<any>()//({ banner_list: [], fetured_product: [], top_category: [] })
  const [showLoading, setShowLoading] = useState(false)
  const doRefresh = async (event: CustomEvent<RefresherEventDetail>) => {
    setHomeData(await API.getHomeData())
    event.detail.complete()
  }
  useIonViewWillEnter(async () => {
    setHomeData(await API.getHomeData())
  })
  return (
    <IonPage>
      <IonContent>
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent
            pullingIcon={caretDownOutline}
            refreshingSpinner="circular"
            refreshingText="">
          </IonRefresherContent>
        </IonRefresher>
        {homeData ?
          <Swiper
            loop={true}
            autoplay={{ 'delay': 5000, 'disableOnInteraction': false }}
            navigation={false}
            pagination={{ 'dynamicBullets': true }}>
            {homeData?.banner_list?.map((banner: any) => {
              return (
                <SwiperSlide key={banner.banner_id}>
                  <IonImg src={banner.image} className="slide-image"></IonImg>
                </SwiperSlide>
              )
            })}
          </Swiper> : <></>}
        <h1 className="ion-margin-horizontal">{t('topCategories')}</h1>
        <AdCategoryGrid categories={homeData?.top_category} />
        <h1 className="ion-margin-horizontal">{t('featuredProducts')}</h1>
        <IonGrid className="ion-padding-horizontal">
          <IonRow>
            {homeData?.fetured_product?.map((ad: any, index: number) => {
              return (
                <IonCol key={index} size-xs="12" sizeSm="12" sizeMd="6" sizeLg="4" sizeXl="3">
                  <AdCard
                    id={ad.product_id}
                    title={ad.title}
                    image={ad.image}
                    startDate={new Date(ad.start_date.split('-').reverse().join('-'))}
                    price={ad.price}
                    isFavorited={ad.is_favourite}
                    categoryTitle={ad.category_title}
                    ad={ad} />
                </IonCol>
              )
            })}
          </IonRow>
        </IonGrid>
      </IonContent>
      <IonLoading
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={t('loading')} />
    </IonPage>
  )
}

export default Home
