import React from 'react'
import { v4 as uuidv4 } from 'uuid'
import {
  credentials,
  device,
  profile,
  reset,
  category,
  ad,
  filter,
  search,
  myAd,
  favorite,
  content,
  contact,
  adListing,
  userContext,
} from './types/api'

const url = 'https://backend.adzat.net'
const api = '/api/v1'
const auth = JSON.parse(localStorage.getItem('auth') ?? JSON.stringify({
  token: null,
  type: null
}))
const getToken = async ({ device_id, device_type }: device) => {
  const endpoint = '/get-token'
  const headers = new Headers()
  headers.append('Content-Type', 'application/json')
  const formData = new FormData()
  if (device_id !== undefined) formData.append('device_id', device_id)
  if (device_type !== undefined) formData.append('device_type', device_type)
  const options: RequestInit = {
    method: 'POST',
    headers: headers,
    body: formData,
    redirect: 'follow'
  }
  const response = await fetch(url + api + endpoint, options)
  const json = await response.json()
  auth.token = json.data.token.token ?? auth.token
  auth.type = json.data.token.type ?? auth.type
  localStorage.setItem('auth', JSON.stringify(auth))
  if (Object.keys(json.data).length) return json.data.token
  return json
}

const logIn = async ({ email, password }: credentials) => {
  const endpoint = '/login'
  const headers = new Headers()
  headers.append('Authorization', `${auth.type} ${auth.token}`)
  const formData = new FormData()
  formData.append('email', email)
  formData.append('password', password)
  const options: RequestInit = {
    method: 'POST',
    headers: headers,
    body: formData,
    redirect: 'follow'
  }
  const response = await fetch(url + api + endpoint, options)
  if (response.status === 401) await getToken({})
  const json = await response.json()
  Object.assign(auth, json.data.token)
  return json
}

const getCountries = async () => {
  const endpoint = '/get-countries'
  const headers = new Headers()
  headers.append('Authorization', `${auth.type} ${auth.token}`)
  const options: RequestInit = {
    method: 'GET',
    headers: headers,
    redirect: 'follow'
  }
  const response = await fetch(url + api + endpoint, options)
  if (response.status === 401) await getToken({})
  const json = await response.json()
  if (Object.keys(json.data).length) return json.data.country_list
  return json
}

const signUp = async ({ name, email, password, phone_no, gender, country_id, language, device_type, device_id }: profile) => {
  const endpoint = '/signup'
  const headers = new Headers()
  headers.append('Authorization', `${auth.type} ${auth.token}`)
  const formData = new FormData()
  if (name !== undefined) formData.append('name', name)
  if (email !== undefined) formData.append('email', email)
  if (password !== undefined) formData.append('password', password)
  if (phone_no !== undefined) formData.append('phone_no', phone_no)
  if (gender !== undefined) formData.append('gender', gender)
  if (country_id !== undefined) formData.append('country_id', `${country_id}`)
  if (language !== undefined) formData.append('language', language)
  if (device_type !== undefined) formData.append('device_type', device_type)
  if (device_id !== undefined) formData.append('device_id', device_id)
  const options: RequestInit = {
    method: 'POST',
    headers: headers,
    body: formData,
    redirect: 'follow'
  }
  const response = await fetch(url + api + endpoint, options)
  if (response.status === 401) await getToken({})
  const json = await response.json()
  Object.assign(auth, json.data.token)
  if (Object.keys(json.data).length) return json.data.user
  return json
}

const forgetPassword = async ({ email }: { email: string }) => {
  const endpoint = '/forgot-password'
  const headers = new Headers()
  headers.append('Authorization', `${auth.type} ${auth.token}`)
  const formData = new FormData()
  formData.append('email', email)
  const options: RequestInit = {
    method: 'POST',
    headers: headers,
    body: formData,
    redirect: 'follow'
  }
  const response = await fetch(url + api + endpoint, options)
  if (response.status === 401) await getToken({})
  const json = await response.json()
  if (Object.keys(json.data).length) return json.data
  return json
}

const resetPassword = async ({ otp, password, confirm_password }: reset) => {
  const endpoint = '/reset-password'
  const headers = new Headers()
  headers.append('Authorization', `${auth.type} ${auth.token}`)
  const formData = new FormData()
  formData.append('otp', otp)
  formData.append('password', password)
  formData.append('confirm_password', confirm_password)
  const options: RequestInit = {
    method: 'POST',
    headers: headers,
    body: formData,
    redirect: 'follow'
  }
  const response = await fetch(url + api + endpoint, options)
  if (response.status === 401) await getToken({})
  const json = await response.json()
  if (Object.keys(json.data).length) return json.data
  return json
}

const editProfile = async ({ name, email, phone_no, gender, country_id, language, profile_pic }: profile) => {
  const endpoint = '/edit-profile'
  const headers = new Headers()
  headers.append('Authorization', `${auth.type} ${auth.token}`)
  const formData = new FormData()
  if (name !== undefined) formData.append('name', name);
  if (email !== undefined) formData.append('email', email);
  if (phone_no !== undefined) formData.append('phone_no', phone_no);
  if (gender !== undefined) formData.append('gender', gender);
  if (country_id !== undefined) formData.append('country_id', `${country_id}`);
  if (language !== undefined) formData.append('language', language);
  if (profile_pic !== undefined) formData.append('profile_pic', profile_pic, uuidv4());
  const options: RequestInit = {
    method: 'POST',
    headers: headers,
    body: formData,
    redirect: 'follow'
  }
  const response = await fetch(url + api + endpoint, options)
  if (response.status === 401) await getToken({})
  const json = await response.json()
  if (Object.keys(json.data).length) return json.data.user
  return json
}

const logOut = async () => {
  const endpoint = '/logout'
  const headers = new Headers()
  headers.append('Authorization', `${auth.type} ${auth.token}`)
  const formData = new FormData()
  const options: RequestInit = {
    method: 'POST',
    headers: headers,
    body: formData,
    redirect: 'follow'
  }
  const response = await fetch(url + api + endpoint, options)
  if (response.status === 401) await getToken({})
  const json = await response.json()
  localStorage.removeItem('auth')
  if (json.data.length) return json.data.message
  return json
}

const changePassword = async ({ old_password, new_password }: { old_password: string, new_password: string }) => {
  const endpoint = '/change-password'
  const headers = new Headers()
  headers.append('Authorization', `${auth.type} ${auth.token}`)
  const formData = new FormData()
  formData.append('old_password', old_password)
  formData.append('new_password', new_password)
  const options: RequestInit = {
    method: 'POST',
    headers: headers,
    body: formData,
    redirect: 'follow'
  }
  const response = await fetch(url + api + endpoint, options)
  if (response.status === 401) await getToken({})
  const json = await response.json()
  return json.success
}

const getCategoryList = async ({ category_type, is_top, parent_id, search }: category) => {
  const endpoint = '/get-category'
  const headers = new Headers()
  headers.append('Authorization', `${auth.type} ${auth.token}`)
  const formData = new FormData()
  if (category_type !== undefined) formData.append('category_type', `${category_type}`)
  if (is_top !== undefined) formData.append('is_top', `${+is_top}`)
  if (parent_id !== undefined) formData.append('parent_id', `${parent_id}`)
  if (search !== undefined) formData.append('search', `${search}`)
  const options: RequestInit = {
    method: 'POST',
    headers: headers,
    body: formData,
    redirect: 'follow'
  }
  const response = await fetch(url + api + endpoint, options)
  if (response.status === 401) await getToken({})
  const json = await response.json()
  if (Object.keys(json.data).length) return json.data.category_list
  return json
}

const getBannerList = async () => {
  const endpoint = '/banner-list'
  const headers = new Headers()
  headers.append('Authorization', `${auth.type} ${auth.token}`)
  const options: RequestInit = {
    method: 'GET',
    headers: headers,
    redirect: 'follow'
  }
  const response = await fetch(url + api + endpoint, options)
  if (response.status === 401) await getToken({})
  const json = await response.json()
  if (Object.keys(json.data).length) return json.data.banner_list
  return json
}

const addEditAd = async ({ title, category_id, country_id, description, images, price, phone_no, whatsapp_no, auto_renew, ad_id, removed_image }: ad) => {
  const endpoint = '/add-edit-product'
  const headers = new Headers()
  headers.append('Authorization', `${auth.type} ${auth.token}`)
  const formData = new FormData()
  formData.append('title', title)
  formData.append('category_id', `${category_id}`)
  formData.append('country_id', `${country_id}`)
  formData.append('description', description)
  Array().forEach.call(images, (image, index) => { formData.append(`image[${index}]`, image, uuidv4()) })
  formData.append('price', `${price}`)
  formData.append('phone_no', phone_no)
  formData.append('whatsapp_no', whatsapp_no)
  formData.append('auto_renew', `${+auto_renew}`)
  if (ad_id !== undefined) formData.append('product_id', `${ad_id}`)
  if (removed_image !== undefined) formData.append('removed_image', removed_image)
  const options: RequestInit = {
    method: 'POST',
    headers: headers,
    body: formData,
    redirect: 'follow'
  }
  const response = await fetch(url + api + endpoint, options)
  if (response.status === 401) await getToken({})
  const json = await response.json()
  if (Object.keys(json.data).length) return json.data.product_id
  return json
}

const getAds = async ({ is_featured, category_id, offset, keyword, country_id, price }: filter) => {
  const endpoint = '/get-products'
  const headers = new Headers()
  headers.append('Authorization', `${auth.type} ${auth.token}`)
  const formData = new FormData()
  if (is_featured !== undefined) formData.append('is_featured', `${+is_featured}`)
  if (category_id !== undefined) formData.append('category_id', `${category_id}`)
  if (offset !== undefined) formData.append('offset', `${offset}`)
  if (keyword !== undefined) formData.append('keyword', keyword)
  if (country_id !== undefined) formData.append('country_id', `${country_id}`)
  if (price !== undefined) formData.append('price', `${price}`)
  const options: RequestInit = {
    method: 'POST',
    headers: headers,
    body: formData,
    redirect: 'follow'
  }
  const response = await fetch(url + api + endpoint, options)
  if (response.status === 401) await getToken({})
  const json = await response.json()
  if (Object.keys(json.data).length) return json.data.products_list
  return json
}

const searchAds = async ({ country_id, price, category_id, keyword }: search) => {
  const endpoint = '/search-products'
  const headers = new Headers()
  headers.append('Authorization', `${auth.type} ${auth.token}`)
  const formData = new FormData()
  if (country_id !== undefined) formData.append('country_id', `${country_id}`);
  if (price !== undefined) formData.append('price', `${price}`);
  if (category_id !== undefined) formData.append('category_id', `${category_id}`);
  if (keyword !== undefined) formData.append('keyword', keyword);
  const options: RequestInit = {
    method: 'POST',
    headers: headers,
    body: formData,
    redirect: 'follow'
  }
  const response = await fetch(url + api + endpoint, options)
  if (response.status === 401) await getToken({})
  const json = await response.json()
  if (Object.keys(json.data).length) return json.data.products_list
  return json
}

const getMyAds = async ({ page, offset, keyword, category_id, country_id, price }: myAd) => {
  const endpoint = '/my-products'
  const headers = new Headers()
  headers.append('Authorization', `${auth.type} ${auth.token}`)
  const formData = new FormData()
  if (page !== undefined) formData.append('page', `${page}`)
  if (offset !== undefined) formData.append('offset', `${offset}`)
  if (keyword !== undefined) formData.append('keyword', keyword)
  if (category_id !== undefined) formData.append('category_id', `${category_id}`)
  if (country_id !== undefined) formData.append('country_id', `${country_id}`)
  if (price !== undefined) formData.append('price', `${price}`)
  const options: RequestInit = {
    method: 'POST',
    headers: headers,
    body: formData,
    redirect: 'follow'
  }
  const response = await fetch(url + api + endpoint, options)
  if (response.status === 401) await getToken({})
  const json = await response.json()
  if (Object.keys(json.data).length) return json.data.my_products
  return json
}

const getFavoritesList = async ({ page, offset }: myAd) => {
  const endpoint = '/get-favorite-list'
  const headers = new Headers()
  headers.append('Authorization', `${auth.type} ${auth.token}`)
  const formData = new FormData()
  if (page !== undefined) formData.append('page', `${page}`)
  if (offset !== undefined) formData.append('offset', `${offset}`)
  const options: RequestInit = {
    method: 'POST',
    headers: headers,
    body: formData,
    redirect: 'follow'
  }
  const response = await fetch(url + api + endpoint, options)
  if (response.status === 401) await getToken({})
  const json = await response.json()
  if (Object.keys(json.data).length) return json.data.my_products
  return json
}

const getAdDetails = async ({ ad_id }: { ad_id: number }) => {
  const endpoint = '/get-product-details'
  const headers = new Headers()
  headers.append('Authorization', `${auth.type} ${auth.token}`)
  const formData = new FormData()
  formData.append('product_id', `${ad_id}`)
  const options: RequestInit = {
    method: 'POST',
    headers: headers,
    body: formData,
    redirect: 'follow'
  }
  const response = await fetch(url + api + endpoint, options)
  if (response.status === 401) await getToken({})
  const json = await response.json()
  if (Object.keys(json.data).length) return json.data.product_details
  return json
}

const getHomeData = async () => {
  const endpoint = '/dashboard'
  const headers = new Headers()
  headers.append('Authorization', `${auth.type} ${auth.token}`)
  const options: RequestInit = {
    method: 'GET',
    headers: headers,
    redirect: 'follow'
  }
  const response = await fetch(url + api + endpoint, options)
  if (response.status === 401) await getToken({})
  const json = await response.json()
  if (Object.keys(json.data).length) return json.data
  return json
}

const removeAd = async ({ ad_id }: { ad_id: number }) => {
  const endpoint = '/remove-product'
  const headers = new Headers()
  headers.append('Authorization', `${auth.type} ${auth.token}`)
  const formData = new FormData()
  formData.append('product_id', `${ad_id}`);
  const options: RequestInit = {
    method: 'POST',
    headers: headers,
    body: formData,
    redirect: 'follow'
  }
  const response = await fetch(url + api + endpoint, options)
  if (response.status === 401) await getToken({})
  const json = await response.json()
  if (Object.keys(json.data).length) return json.data.message
  return json
}

const reportAd = async ({ ad_id, notes }: { ad_id: number, notes: string }) => {
  const endpoint = '/report-product'
  const headers = new Headers()
  headers.append('Authorization', `${auth.type} ${auth.token}`)
  const formData = new FormData()
  formData.append('product_id', `${ad_id}`)
  formData.append('notes', notes)
  const options: RequestInit = {
    method: 'POST',
    headers: headers,
    body: formData,
    redirect: 'follow'
  }
  const response = await fetch(url + api + endpoint, options)
  if (response.status === 401) await getToken({})
  const json = await response.json()
  if (Object.keys(json.data).length) return json.data.message
  return json
}

const setFavorite = async ({ ad_id, is_favorite }: favorite) => {
  const endpoint = '/add-edit-favorite'
  const headers = new Headers()
  headers.append('Authorization', `${auth.type} ${auth.token}`)
  const formData = new FormData()
  formData.append('product_id', `${ad_id}`)
  formData.append('is_favorite', `${+is_favorite}`)
  const options: RequestInit = {
    method: 'POST',
    headers: headers,
    body: formData,
    redirect: 'follow'
  }
  const response = await fetch(url + api + endpoint, options)
  if (response.status === 401) await getToken({})
  const json = await response.json()
  if (Object.keys(json.data).length) return json.data.message
  return json
}

const getCMSContent = async ({ type }: content) => {
  const endpoint = '/get-cms-content'
  const headers = new Headers()
  headers.append('Authorization', `${auth.type} ${auth.token}`)
  const formData = new FormData()
  formData.append('type', type)
  const options: RequestInit = {
    method: 'POST',
    headers: headers,
    body: formData,
    redirect: 'follow'
  }
  const response = await fetch(url + api + endpoint, options)
  if (response.status === 401) await getToken({})
  const json = await response.json()
  if (Object.keys(json.data).length) return json.data.cms_content.content
  return json
}

const contactUs = async ({ title, email, name, message, phone }: contact) => {
  const endpoint = '/contact-us'
  const headers = new Headers()
  //headers.append('Authorization', `${auth.type} ${auth.token}`)
  const formData = new FormData()
  formData.append('title', title)
  formData.append('email', email)
  formData.append('name', name)
  formData.append('message', message)
  formData.append('phone', phone)
  const options: RequestInit = {
    method: 'POST',
    headers: headers,
    body: formData,
    redirect: 'follow'
  }
  const response = await fetch(url + api + endpoint, options)
  if (response.status === 401) await getToken({})
  const json = await response.json()
  if (Object.keys(json.data).length) return json.data.message
  return json
}

const countryIdToCode: { [key: number]: number } = {
  119: 965,
}

export {
  getToken,
  logIn,
  getCountries,
  signUp,
  forgetPassword,
  resetPassword,
  editProfile,
  logOut,
  changePassword,
  getCategoryList,
  getBannerList,
  addEditAd,
  getAds,
  searchAds,
  getMyAds,
  getFavoritesList,
  getAdDetails,
  getHomeData,
  removeAd,
  reportAd,
  setFavorite,
  getCMSContent,
  contactUs,
  countryIdToCode
}