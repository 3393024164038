import 'bootstrap/dist/css/bootstrap.min.css'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import reportWebVitals from './reportWebVitals'
import './i18n'
import * as API from './api'
import { credentials, adListing } from './types/api'
import { IonCol, IonContent, IonGrid, IonImg, IonPage, IonRow } from '@ionic/react'
import { defaultUserContext, UserContext } from './contexts'

const UserContextValue = defaultUserContext
const render = () => {
  ReactDOM.render(
    <React.StrictMode>
      <UserContext.Provider value={UserContextValue}>
        <App />
      </UserContext.Provider>
    </React.StrictMode>,
    document.getElementById('root')
  )
}
const init = async () => {
  const auth = JSON.parse(localStorage.getItem('auth') ?? JSON.stringify({
    token: null,
    type: null
  }))
  const doAuth = async () => { Object.assign(auth, await API.getToken({})) }
  if (!auth.token) await doAuth()
  const userInfo = await API.editProfile({})
  if (!userInfo.user_id) { return render() }
  UserContextValue.isAuthenticated = true
  Object.assign(UserContextValue.information, userInfo)
  UserContextValue.setFavorites(await API.getFavoritesList({}))
  render()
}

ReactDOM.render(
  <React.StrictMode>
    <IonPage>
      <IonContent className="ion-padding">
        <IonGrid style={{ height: '100%' }}>
          <IonRow class="ion-align-items-center" style={{ height: '100%' }}>
            <IonCol class="ion-text-center">
              <IonImg src={`/assets/logo.${window.matchMedia('(prefers-color-scheme: dark)').matches ? 'light' : 'dark'}.png`} className="splash-logo ion-padding" />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  </React.StrictMode>,
  document.getElementById('root')
)
init()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log)