import './FavoriteButton.css'
import {
  IonIcon,
  IonButton,
  useIonViewWillEnter,
} from '@ionic/react'
import {
  heart,
  heartOutline,
} from 'ionicons/icons'
import { MouseEventHandler, useContext, useReducer, useState, useRef } from 'react'
import { setFavorite } from '../api'
import { UserContext } from '../contexts'
import { adListing } from '../types/api'

interface FavoriteButtonProps {
  id: number,
  isFavorited: boolean,
  Fixed: boolean,
  slot?: string,
  className?: string,
  ad: adListing
}

const FavoriteButton: React.FC<FavoriteButtonProps> = ({ id, isFavorited, Fixed, slot, className, ad }) => {
  const currentUserContext = useContext(UserContext)
  const [currentFavorite, setCurrentFavorite] = useState<boolean>(isFavorited)
  useIonViewWillEnter(async () => {
    console.log(id, currentUserContext.favorites[id]?.is_favourite, ad.title)
    setCurrentFavorite(currentUserContext.favorites[id]?.is_favourite ?? isFavorited)
  }, [currentFavorite])
  const editFavorite: MouseEventHandler<HTMLIonButtonElement> = async event => {
    event.preventDefault()
    event.stopPropagation()
    let favorite
    if (currentUserContext.favorites[id]) {
      favorite = !currentUserContext.favorites[id].is_favourite
      ad.is_favourite = favorite
      currentUserContext.favorites[id].is_favourite = favorite
    } else {
      favorite = !isFavorited
      ad.is_favourite = favorite
      currentUserContext.setFavorite(ad)
    }
    setCurrentFavorite(favorite)
    const response = await setFavorite({ ad_id: id, is_favorite: favorite })
  }
  return (
    currentUserContext.isAuthenticated
      ?
      <IonButton color="dark" className={`ad-favorite-button${Fixed ? ' fixed' : ''} ion-no-padding${className ? ` ${className}` : ''}`} onClick={editFavorite} slot={slot ? slot : ''}>
        <IonIcon icon={
          currentFavorite
            ? heart
            : heartOutline
        } className="ad-favorite-button-icon" />
      </IonButton>
      :
      <></>
  )
}

export default FavoriteButton