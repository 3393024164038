import React from 'react'
import { getI18n } from 'react-i18next'
import { getToken, logIn, logOut } from './api'
import { adListing, credentials } from './types/api'
import { userContext } from './types/contexts'

const defaultUserContext: userContext = {
  isAuthenticated: false,
  setAuthenticated: (authenticated: boolean) => {
    defaultUserContext.isAuthenticated = authenticated
  },
  logIn: async ({ email, password }: credentials) => {
    const response = await logIn({ email, password })
    if (response.status === 401) await getToken({})
    console.log(response)
    if (response.length || !response.success) return response
    defaultUserContext.setAuthenticated(true)
    return response
  },
  logOut: async () => {
    await logOut()
    await getToken({})
    defaultUserContext.setAuthenticated(false)
  },
  information: {},
  favorites: [],
  setFavorite: (ad: adListing) => { defaultUserContext.favorites[ad.product_id] = ad },
  setFavorites: (favorites: adListing[]) => { favorites.forEach((favorite: adListing) => { defaultUserContext.setFavorite(favorite) }) },
  getFavorite: (adId: number) => <adListing>defaultUserContext.favorites[adId],
  changeLanguage: (language: string) => {
    const i18n = getI18n()
    i18n.changeLanguage(language)
    const direction = language === 'ar' ? 'rtl' : 'ltr'
    document.querySelector(':root')?.setAttribute('dir', direction)
  },
}

const UserContext = React.createContext(defaultUserContext)

export { defaultUserContext, UserContext }