import './Password.css'
import {
  FormEventHandler,
  useRef,
  useState
} from 'react'
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonLoading,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonAlert
} from '@ionic/react'
import { useTranslation } from 'react-i18next'
import { changePassword } from '../../api'

const Password: React.FC = () => {
  const { t } = useTranslation()
  const [showLoading, setShowLoading] = useState(false)
  const oldPasswordInput = useRef<any>()
  const newPasswordInput = useRef<any>()
  const [showMessage] = useIonAlert()
  const submit: FormEventHandler = async e => {
    e.preventDefault()
    setShowLoading(true)
    const success = await changePassword({
      old_password: oldPasswordInput.current.value,
      new_password: newPasswordInput.current.value
    })
    if (success) {
      showMessage({
        message: t('passwordChanged'),
        buttons: [t('okay')],
        onDidDismiss: e => { oldPasswordInput.current.value = newPasswordInput.current.value = '' },
      })
    }
    else {
      showMessage({
        message: t('passwordIncorrect'),
        buttons: [t('okay')],
        onDidDismiss: e => { oldPasswordInput.current.value = newPasswordInput.current.value = '' },
      })
    }
    setShowLoading(false)
  }
  return (
    <IonPage>
      <IonHeader translucent>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/settings" text={t('back')}></IonBackButton>
          </IonButtons>
          <IonTitle></IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <form onSubmit={submit}>
          <IonItem className="ion-margin-end">
            <IonLabel position="floating">{t('oldPassword')}</IonLabel>
            <IonInput ref={oldPasswordInput} name="oldpassword" type="password" autocomplete="current-password" clearInput required />
          </IonItem>
          <IonItem className="ion-margin-end">
            <IonLabel position="floating">{t('newPassword')}</IonLabel>
            <IonInput ref={newPasswordInput} name="password" type="password" clearInput required />
          </IonItem>
          <IonButton className="ion-margin" type="submit" expand="block">
            {t('submit')}
          </IonButton>
        </form>
      </IonContent>
      <IonLoading
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={t('loading')} />
    </IonPage>
  )
}

export default Password