import React, { useState, useEffect } from 'react'
import { Redirect, Route } from 'react-router-dom'
import {
  IonContent,
  IonRouterOutlet,
  IonTabs,
  IonTabBar,
  IonTabButton,
  IonIcon,
  IonLabel,
  useIonViewWillEnter,
  IonRefresher,
  IonRefresherContent
} from '@ionic/react'
import {
  bagOutline,
  caretDownOutline,
  newspaperOutline
} from 'ionicons/icons'
import './Categories.css'
import AdCategoryGrid from '../../components/AdCategoryGrid'
import * as API from '../../api'
import { useTranslation } from 'react-i18next'
import { RefresherEventDetail } from '@ionic/core'

const Categories: React.FC = () => {
  const { t } = useTranslation()
  const [productCategoriesData, setProductCategoriesData] = useState<any>()
  const [serviceCategoriesData, setServiceCategoriesData] = useState<any>()
  useIonViewWillEnter(async () => {
    setProductCategoriesData(await API.getCategoryList({ category_type: 0 }))
    setServiceCategoriesData(await API.getCategoryList({ category_type: 1 }))
  })
  const doRefresh = async (event: CustomEvent<RefresherEventDetail>) => {
    setProductCategoriesData(await API.getCategoryList({ category_type: 0 }))
    setServiceCategoriesData(await API.getCategoryList({ category_type: 1 }))
    event.detail.complete()
  }
  return (
    <IonTabs>
      <IonRouterOutlet>
        <Route exact path="/categories/products" render={() =>
          <IonContent>
            <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
              <IonRefresherContent
                pullingIcon={caretDownOutline}
                refreshingSpinner="circular"
                refreshingText="">
              </IonRefresherContent>
            </IonRefresher>
            <AdCategoryGrid categories={productCategoriesData} />
          </IonContent>
        }>
        </Route>
        <Route exact path="/categories/services" render={() =>
          <IonContent>
            <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
              <IonRefresherContent
                pullingIcon={caretDownOutline}
                refreshingSpinner="circular"
                refreshingText="">
              </IonRefresherContent>
            </IonRefresher>
            <AdCategoryGrid categories={serviceCategoriesData} />
          </IonContent>
        }>
        </Route>
        <Route exact path="/categories">
          <Redirect to="/categories/products" />
        </Route>
      </IonRouterOutlet>
      <IonTabBar slot="top">
        <IonTabButton tab="products" href="/categories/products">
          <IonIcon icon={bagOutline} />
          <IonLabel>{t('products')}</IonLabel>
        </IonTabButton>
        <IonTabButton tab="services" href="/categories/services">
          <IonIcon icon={newspaperOutline} />
          <IonLabel>{t('services')}</IonLabel>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  )
}

export default Categories
