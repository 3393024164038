import 'swiper/swiper-bundle.min.css'
import './Ad.css'
import {
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonIcon,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonBackButton,
  useIonViewWillEnter,
  IonLoading,
  IonRefresherContent,
  IonRefresher,
  RefresherEventDetail,
  IonImg,
  IonButtons,
  IonModal,
  IonText
} from '@ionic/react'
import {
  caretDownOutline,
  shareSocialOutline,
  alertCircleOutline,
  call,
  logoWhatsapp
} from 'ionicons/icons'
import { useTranslation } from 'react-i18next'
import { useContext, useReducer, useState } from 'react'
import { RouteComponentProps } from 'react-router'
import * as API from '../../api'
import { adListing } from '../../types/api'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react'
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper'
import FavoriteButton from '../../components/FavoriteButton'
import { UserContext } from '../../contexts'

SwiperCore.use([Autoplay, Navigation, Pagination])

interface ContentProps extends RouteComponentProps<{ id: string }> { }

const Ad: React.FC<ContentProps> = ({ match }) => { //
  const { t } = useTranslation()
  const [showLoading, setShowLoading] = useState(false)
  const [adData, setAdData] = useState<any>()
  const [showReportModal, setShowReportModal] = useState(false)
  const currentUserContext = useContext(UserContext)
  const getId = () => parseInt(window.location.href.split('/').at(-1) ?? '0')//parseInt(new window.URLPattern(window.location.href).pathname.split('/')[2])//parseInt(match.params.id)
  useIonViewWillEnter(async () => {
    const id = getId()
    setShowLoading(true)
    setAdData(await API.getAdDetails({ ad_id: id }))
    setShowLoading(false)
  })
  const doRefresh = async (event: CustomEvent<RefresherEventDetail>) => {
    const id = getId()
    setAdData(await API.getAdDetails({ ad_id: id }))
    event.detail.complete()
  }
  const formatDate = (dateString: string) => {
    if (!dateString || dateString === '') { return '' }
    const dateArray = dateString.split('-')
    const date = new Date(dateArray.reverse().join('-'))
    return `${date.getFullYear()}-${date.getUTCMonth() + 1}-${date.getUTCDate()}`
  }
  const formatPhoneNumber = () =>
    adData?.seller_phone_number.indexOf('+') !== 0
      ? `+${API.countryIdToCode[adData?.country_id]}${adData?.seller_phone_number?.replace(' ', '')}`
      : `${adData?.seller_phone_number?.replace(' ', '')}`
  const reportAd = () => {
    setShowReportModal(true)
    console.log(adData)
  }
  const shareAd = async () => {
    const shareData = {
      title: `${adData.title}`,
      text: `${adData.description}`,
      url: `${window.location.href}`,
    }
    await navigator?.share?.(shareData)
  }
  const callSeller = () => {
    const phone = formatPhoneNumber()
    window.open(`tel:${phone}`)
  }
  const messageSeller = () => {
    const phone = formatPhoneNumber()
    window.open(`whatsapp://send?phone=${phone}`)
  }
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/categories" text={t('back')}></IonBackButton>
          </IonButtons>
          {/* <IonTitle>{adData?.title}</IonTitle> */}
          <IonButtons slot="end">
            <IonButton onClick={shareAd}>
              <IonIcon icon={shareSocialOutline} />
            </IonButton>
            <IonButton onClick={reportAd}>
              <IonIcon icon={alertCircleOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonModal isOpen={showReportModal}>
          <p>Report the Ad</p>
          <IonButton onClick={() => setShowReportModal(false)}>{t('close')}</IonButton>
        </IonModal>
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent
            pullingIcon={caretDownOutline}
            refreshingSpinner="circular"
            refreshingText="">
          </IonRefresherContent>
        </IonRefresher>
        <Swiper
          loop={true}
          autoplay={false}
          navigation={false}
          pagination={{ 'dynamicBullets': true }}>
          <SwiperSlide key={1}>
            <IonImg src={adData?.image} className="slide-image"></IonImg>
          </SwiperSlide>
        </Swiper>
        <IonGrid>
          <IonRow>
            <IonCol className="ion-text-center"><small>{formatDate(adData?.start_date)}</small></IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="10">
              <h1 className="ion-no-padding ion-no-margin">{adData?.title}</h1>
            </IonCol>
            <IonCol size="2" className="ion-text-end ion-no-padding ion-no-margin">
              {adData?.is_favourite !== undefined
                ? <FavoriteButton id={adData?.product_id} isFavorited={adData?.is_favourite} Fixed={false} ad={adData} />
                : <></>}
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="8">
              {adData?.description}
            </IonCol>
            <IonCol size="4" className="ion-text-end">
              <IonText color="warning"><h1 className="ion-no-padding ion-no-margin">{adData?.price}</h1>{t('kd')}</IonText>
            </IonCol>
          </IonRow>
          <IonRow className="ion-margin-top ion-padding-top">
            <IonCol className="ion-text-center" size-xs="6" sizeSm="3" sizeMd="3" sizeLg="2" sizeXl="1">
              <IonButton color="dark" onClick={callSeller}>
                <IonIcon slot="start" icon={call} />
                {adData?.seller_phone_number}
              </IonButton>
            </IonCol>
            <IonCol className="ion-text-center" size-xs="6" sizeSm="3" sizeMd="3" sizeLg="2" sizeXl="1">
              <IonButton color="dark" onClick={messageSeller}>
                <IonIcon slot="start" icon={logoWhatsapp} />
                {adData?.seller_whatsapp_number}
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      <IonLoading
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={t('loading')} />
    </IonPage>
  )
}

export default Ad
