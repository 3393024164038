import React, { useState } from 'react';
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonList,
  IonItem,
  IonIcon,
  IonLabel,
  useIonPicker,
  IonLoading,
} from '@ionic/react'
import {
  lockOpenOutline,
  languageOutline,
} from 'ionicons/icons'
import './Settings.css'
import { useTranslation } from 'react-i18next'
import { UserContext } from '../../contexts'
import { useContext } from 'react'

const Settings: React.FC = () => {
  const { t, i18n } = useTranslation()
  const currentUserContext = useContext(UserContext)
  const [showLoading, setShowLoading] = useState(false)
  const [present] = useIonPicker()
  const languagePicker = () => {
    present({
      buttons: [
        { text: t('cancel'), role: 'cancel', },
        {
          text: t('confirm'), handler: selected => {
            currentUserContext.changeLanguage(selected.language.value)
          },
        },
      ],
      columns: [
        {
          name: 'language',
          options: [
            { text: 'English', value: 'en' },
            { text: 'عربي', value: 'ar' }
          ],
        },
      ],
    })
  }
  return (
    <IonPage>
      <IonHeader translucent>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/account" text={t('back')}></IonBackButton>
          </IonButtons>
          <IonTitle></IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonList className="ion-margin-top">
          {currentUserContext.isAuthenticated
            ?
            <IonItem button routerLink="/settings/password">
              <IonIcon slot="start" icon={lockOpenOutline}></IonIcon>
              <IonLabel>
                {t('changePassword')}
              </IonLabel>
            </IonItem>
            :
            <></>
          }
          <IonItem button onClick={languagePicker}>
            <IonIcon slot="start" icon={languageOutline}></IonIcon>
            <IonLabel>
              {t('changeLanguage')}
            </IonLabel>
          </IonItem>
        </IonList>
      </IonContent>
      <IonLoading
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={t('loading')} />
    </IonPage>
  )
}

export default Settings;
